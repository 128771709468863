import Axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import MockController from './MockController'
import moment from 'moment'

let mock = null

const getQueryParams = url => {
  const searchString = (url && url.split('?')[1]) || ''
  const searchParams = searchString.split('&')
  const queryParams = {}

  searchParams.forEach(searchParam => {
    const searchParamParts = searchParam.split('=')
    queryParams[searchParamParts[0]] = decodeURIComponent(searchParamParts[1])
  })

  return queryParams
}

const initGet = (route, mockRoute, customReply) => {
  if (customReply) mock.onGet(new RegExp(route)).reply(customReply)
  else mock.onGet(new RegExp(route)).reply(200, MockController.get(mockRoute || route))
}

const initPost = (route, mockRoute, customReply) => {
  if (customReply) mock.onPost(new RegExp(route)).reply(customReply)
  else mock.onPost(new RegExp(route)).reply(200, MockController.get(mockRoute || route))
}

/**
 * Initializes all endpoint stubs
 *
 * @private
 * @function initializeStubs
 */
const initializeStubs = () => {
  // ALERTS
  initGet('/alerts/v1/verification/status/jpmc-primary')
  initGet('/alerts/v1/subscriptions')
  initGet('/alerts/v1/profile')
  initGet('/alerts/v1/sortable-history*', null, ({ url }) => {
    const mockData = MockController.get('/alerts/v1/sortable-history')
    const params = getQueryParams(url)
    let identifier

    if (params.offset > 0) {
      identifier = 'noInputs'
      const newMock = mockData[identifier].data.items.map((item, index) => ({ ...item, orderId: item.orderId + Math.random() }))
      return [ 200, { data: { items: newMock } }]
    } else if (params.venueXid && params.alertCategory) {
      identifier = params.alertCategory
    } else if (params.venueXid) {
      identifier = 'venueXid'
    } else if (params.alertCategory) {
      identifier = params.alertCategory
    } else {
      identifier = 'noInputs'
    }

    // copy the mock data body
    const returnData = { ...mockData[identifier] }

    // copy just the items, this will be modified whenever request params require different data for sortable history
    let returnDataItems = [ ...returnData.data.items ]

    while (
      returnDataItems.length < params.limit &&
      returnDataItems.length < returnData.data.pagination.totalItems
    ) {
      returnDataItems = [
        ...returnDataItems,
        ...mockData[identifier].data.items.map(
          (item, index) => ({ ...item, orderId: item.orderId + Math.random() })
        )
      ]
    }

    if (params.limit < returnData.data.pagination.totalItems) {
      returnDataItems = returnDataItems.slice(0, params.limit)
    } else {
      returnDataItems = returnDataItems.slice(0, returnData.data.pagination.totalItems)
    }

    returnData.data.items = returnDataItems

    returnData.data.pagination.limit = parseInt(params.limit)
    returnData.data.pagination.currentItemCount = returnDataItems.length

    console.log(returnData.data)

    return [ 200, returnData ]
  })

  // CHARTWORKS
  initGet('/apiman-gateway/MOD/chartworks-image/1.0/Chart/sparkLine*', null, ({ url }) => {
    const sparkLineImages = MockController.get('/apiman-gateway/MOD/chartworks-image/1.0/Chart/sparkLine')
    const xid = JSON.parse(decodeURIComponent(getQueryParams(url).inputs)).xid
    const xidMap = { 205778: 'MSFT', 75479734: 'INFO' }
    const data = new Blob([ sparkLineImages[xidMap[xid]] || sparkLineImages.defaultImage ], { type: 'image/png' })
    return [ 200, data, { 'content-type': 'image/png' }]
  })

  // CALENDAR
  initGet('/calendar/v1/eventsByDate*', null, ({ url }) => {
    const eventName = getQueryParams(url).eventName
    let data = {}
    if (eventName === 'ConferenceCall Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=ConferenceCall%20Events')
    if (eventName === 'Dividend Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Dividend%20Events')
    if (eventName === 'Economic Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Economic%20Events')
    if (eventName === 'Earnings Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Earnings%20Events')
    if (eventName === 'Guidance Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Guidance%20Events')
    if (eventName === 'IPO Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=IPO%20Events')
    if (eventName === 'Rating Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Rating%20Events')
    if (eventName === 'Split Events') data = MockController.get('/calendar/v1/eventsByDate?eventName=Split%20Events')
    return [ 200, data ]
  })
  initGet('/calendar/v1/counts*', null, ({ url }) => {
    const dateFormat = 'YYYY-MM-DD'
    const startDate = getQueryParams(url).startDate
    const endDate = getQueryParams(url).endDate
    let tempDate = startDate
    const data = { items: [] }
    while (tempDate <= endDate) {
      data.items.push({
        date: tempDate,
        count: Math.ceil(Math.random() * 100)
      })
      tempDate = moment(tempDate).add(1, 'd').format(dateFormat)
    }
    return [ 200, { data }]
  })

  // CFRA RESEARCH
  initGet('/research/v1/configurations')
  initPost('/research/v1/475/details')

  // CORPORATEACTIONS-DIVIDENDS
  initGet('/corporateactions-dividends/v1/205778/current')
  initGet('/corporateactions-dividends/v1/205778/historical-events')
  initGet('/corporateactions-dividends/v1/238029/current')
  initGet('/corporateactions-dividends/v1/238029/historical-events')

  // CURRENCY-CONVERSION
  initGet('/currency-conversion/v1/rate')

  // EQUITIES-MORNINGSTAR-EARNINGS
  initGet('/equities-morningstar-earnings/v1/details/205778\\?', '/equities-morningstar-earnings/v1/details/205778')

  // EQUITIES-MORNINGSTAR-ESTIMATES
  initGet('/equities-morningstar-estimates/v1/current/205778')
  initGet('/equities-morningstar-estimates/v1/recommendations/205778')
  initGet('/equities-morningstar-estimates/v1/trends/*', '/equities-morningstar-estimates/v1/trends/205778')

  // EQUITIES-MORNINGSTAR-FINANCIALRATIOS
  initGet('/equities-morningstar-financialratios/v1/details/205778')

  // EQUITIES-MORNINGSTAR-PEERS
  initGet('/equities-morningstar-peers/v1/details/205778')

  // EQUITIES-MORNINGSTAR-ASSETCLASSIFICATION
  initGet('/equities-morningstar-assetclassification/v1/details/205778')

  // EQUITIES-MORNINGSTAR-GENERALINFORMATION
  initGet('/equities-morningstar-generalinformation/v1/details/205778')

  // JPMC-QR-API CURRENCY CONVERSION
  initGet('/jpmc-qr-api/v1/currency-conversion')

  // JPMC-QR-API FUNDS CATEGORY NAME
  initGet('/jpmc-qr-api/v1/funds/category-name/238029')
  initGet('/jpmc-qr-api/v1/funds/category-name/52709395')

  // JPMC-QR-API FUNDS DIVIDEND YIELD
  initGet('/jpmc-qr-api/v1/funds/dividend-yield/238029')
  initGet('/jpmc-qr-api/v1/funds/dividend-yield/52709395')

  // JPMC-QR-API FUNDS GROSS EXPENSE RATIO
  initGet('/jpmc-qr-api/v1/funds/gross-expense-ratio/238029')
  initGet('/jpmc-qr-api/v1/funds/gross-expense-ratio/52709395')

  // JPMC-QR-UI FUND LIST
  initGet('/jpmc-qr-api/v1/funds/six-circle-funds')

  // JPMC-QR-UI FUND STRATEGY
  initGet('/jpmc-qr-api/v1/funds/six-circle-fund/strategy/52709395')

  // JPMC-QR-API TRADE STATUS
  initGet('/jpmc-qr-api/v1/funds/trade-status*', null, ({ url }) => {
    const tradeStatus = MockController.get('/jpmc-qr-api/v1/funds/trade-status')
    const requestPathNames = url.split('/')
    const xid = requestPathNames[requestPathNames.length - 1]
    return [ 200, tradeStatus[xid] ]
  })

  // JPMC-QR-API TREASURY YIELD CURVE
  initPost('/jpmc-qr-api/v1/treasury-yield-curve')

  // JPMC-QR-API MUTUAL FUNDS GROWTH 10K
  initGet('/jpmc-qr-api/v1/mutualfunds/growth10k/52709395')

  // JPMC-QR-API PERFORMANCE CHART DATA
  initGet('/jpmc-qr-api/v1/performance/chart-data/*', '/jpmc-qr-api/v1/performance/chart-data/205778')

  // JPMC RESEARCH
  initGet('/jpmc-qr-api-research/v1/ric\\?xid*', '/jpmc-qr-api-research/v1/ric')

  // MANAGEDFUNDS-MORNINGSTAR-ASSETALLOCATION
  initGet('/managedfunds-morningstar-assetallocation/v1/asset-allocation/238029')
  initGet('/managedfunds-morningstar-assetallocation/v1/asset-allocation/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-ANNUALFEES
  initGet('/managedfunds-morningstar-annualfees/v1/details/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-COMPANY-INFORMATION
  initGet('/managedfunds-morningstar-companyinformation/v1/company-information/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-DISTRIBUTIONS-CURRENT
  initGet('/managedfunds-morningstar-distributionscurrent/v1/details/238029')

  // MANAGEDFUNDS-MORNINGSTAR-FUNDATTRIBUTES
  initGet('/managedfunds-morningstar-fundattributes/v1/details/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-DISTRIBUTIONS-HISTORICAL
  initGet('/managedfunds-morningstar-distributionshistorical/v1/details/238029')

  // MANAGEDFUNDS-MORNINGSTAR-MARKETCAPALLOCATION
  initGet('/managedfunds-morningstar-marketcapallocation/v1/details/238029')
  initGet('/managedfunds-morningstar-marketcapallocation/v1/details/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-REGIONALLOCATION
  initGet('/managedfunds-morningstar-geographicallocation/v1/regions/238029')
  initGet('/managedfunds-morningstar-geographicallocation/v1/regions/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-SECTORALLOCATION
  initGet('/managedfunds-morningstar-sectorallocation/v1/sector-allocation/238029')
  initGet('/managedfunds-morningstar-sectorallocation/v1/sector-allocation/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-PORTFOLIOSTATS
  initGet('/managedfunds-morningstar-portfoliostats/v1/details/238029')
  initGet('/managedfunds-morningstar-portfoliostats/v1/details/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-PURCHASEDETAILS

  initGet('/managedfunds-morningstar-purchasedetails/v1/purchase-details/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-RATINGSRISK
  initGet('/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/238029')
  initGet('/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/52709395')

  // MANAGEDFUNDS-MORNINGSTAR-TOPHOLDINGS
  initGet('/managedfunds-morningstar-topholdings/v1/topHoldings*', null, ({ url }) => {
    const topHoldings = MockController.get('/managedfunds-morningstar-topholdings/v1/topHoldings')
    const requestPathNames = url.split('/')
    const xid = requestPathNames[requestPathNames.length - 1]
    return [ 200, topHoldings[xid] ]
  })

  // MANAGEDFUNDS-MORNINGSTAR-YIELD
  initGet('/managedfunds-morningstar-yield/v1/details*', null, ({ url }) => {
    const yieldData = MockController.get('/managedfunds-morningstar-yield/v1/details')
    const requestPathNames = url.split('/')
    const xid = requestPathNames[requestPathNames.length - 1]
    const response = yieldData[xid]

    return response ? [ 200, response ] : [ 404, null ]
  })

  // MANAGEDFUNDS-MORNINGSTAR-TOPHOLDINGSREVERSELOOKUP
  initGet('/managedfunds-morningstar-topholdingsreverselookup/v1/topholdingsreverselookup')

  // MARKET RANKINGS
  initGet('/market-rankings/v1/stats\\?*', '/market-rankings/v1/stats')

  // MARKETS EXCHANGE INFORMATION
  initGet('/markets-exchangeinformation/v1/details/*', '/markets-exchangeinformation/v1/details')

  // MARKETS RANKINGS
  initGet('/market-rankings/v1/detail', null, ({ url }) => {
    const data = MockController.get('/market-rankings/v1/detail')
    const count = getQueryParams(url).count

    if (!count) {
      data.data.marketRankings = data.data.marketRankings.slice(0, 5)
    } else {
      data.data.marketRankings = data.data.marketRankings.slice(0, count)
    }

    return [ 200, data ]
  })

  // MORNINGSTAR BENCHMARKS
  initGet('/managedfunds-morningstar-benchmarkinformation/v1/categories/238029')

  // MORNINGSTAR PERFORMANCE
  initGet('/managedfunds-morningstar-performance/v1/trailing-month-end/52709395')
  initGet('/managedfunds-morningstar-performance/v1/trailing-month-end/238029')

  // MORNINGSTAR PROSPECTUS FEES
  initGet('/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/238029')
  initGet('/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/52709395')

  // MORNINGSTAR QUARTER END DATA - MANAGUED FUNDS
  initGet('/managedfunds-morningstar-performance-quarterend/v1/quarterend-trailing-performance*', '/managedfunds-morningstar-performance-quarterend/v1/quarterend-trailing-performance')

  // MORNINGSTAR SHARECLASS
  initGet('/managedfunds-morningstar-shareclass/v1/share-class*', null, ({ url }) => {
    const shareClass = MockController.get('/managedfunds-morningstar-shareclass/v1/share-class')
    const requestPathNames = url.split('/')
    const xid = requestPathNames[requestPathNames.length - 1]
    const response = shareClass[xid]

    return response ? [ 200, response ] : [ 404, null ]
  })

  // MORNINGSTAR STRATEGY
  initGet('/managedfunds-morningstar-strategy/v1/details*', null, ({ url }) => {
    const strategy = MockController.get('/managedfunds-morningstar-strategy/v1/details')
    const requestPathNames = url.split('/')
    const xid = requestPathNames[requestPathNames.length - 1]
    const response = strategy[xid]

    return response ? [ 200, response ] : [ 404, null ]
  })

  // NEWS
  initGet('/news/v1/3109/articles/3024-14971270-18603162/component-type/PRES', null, () =>
    [ 200, MockController.get('/news/v1/3109/articles/3024-14971270-18603162/component-type/PRES').data, { 'Content-Type': 'text/xml' }])
  initGet('/news/v1/3109/articles/3024-14971270-18603162')
  initPost('/news/v1/3109/articles')

  // OPTIONSCHAINS
  initGet('/optionchains/v1/options/chainwithquotes/*', '/optionchains/v1/options/chainwithquotes')
  initGet('/optionchains/v1/options/expirationdates/*', '/optionchains/v1/options/expirationdates')

  // PRICING CURRENT END DAY STATS
  initGet('/pricing-current-endofdaystats/v1/details/*', '/pricing-current-endofdaystats/v1/details')

  // QUOTE
  initGet('/quote/v1/details*', null, ({ url }) => {
    const venueXidsQuote = MockController.get('/quote/v1/details').data.quotes
    const requestedVenueXidString = getQueryParams(url).venueXids
    const requestedVenueXids = requestedVenueXidString.split(',')
    const quotes = venueXidsQuote.filter(venueXidQuote => requestedVenueXids.includes(venueXidQuote.data.venueXid + ''))
    return [ 200, { data: { quotes } }]
  })

  // QUOTE CHANGE PERCENT DATA
  initGet('/quote-changepercent/v1/*', '/quote-changepercent/v1/')

  // WATCHLIST
  initGet('/watchlist/v1/watchlist(?!/)', '/watchlist/v1/watchlist')
  initGet('/watchlist/v1/watchlist/06524c17-5cbf-482d-af9f-0ba990134fb4/holdings')
  initGet('/watchlist/v1/watchlist/257B2DF8-D72C-4E8B-86E9-323E8D4A811E/holdings')
  initGet('/watchlist/v1/watchlist/3714bf05-32b4-46c6-9276-0df253447575/holdings')
  initGet('/watchlist/v1/watchlist/5c4a0e50-6845-4c20-be84-a32eaf1f79d0/holdings')
  initPost('/watchlist/v1/watchlist(?!/)', '/watchlist/v1/watchlist/post')
  initPost('/watchlist/v1/watchlist/.*/holdings', '/watchlist/v1/watchlist/holdings/post')

  // XREF
  initGet('/xref/v1/venue-xids\\?venueXids*', null, ({ url }) => {
    const venueXidsXref = MockController.get('/xref/v1/venue-xids').data.items
    const requestedVenueXidString = getQueryParams(url).venueXids
    const requestedVenueXids = requestedVenueXidString.split(',')
    const xrefArray = venueXidsXref.filter(xref => {
      const xid = xref && xref.xids && xref.xids.venue
      return requestedVenueXids.includes(`${xid}`)
    })
    return [ 200, { data: { items: xrefArray } }]
  })
  initGet('/xref/v1/venues')
  initGet('/xref/v1/symbols/AAPL')
  initGet('/xref/v1/symbols/ANOIX')
  initGet('/xref/v1/symbols/BUFDX')
  initGet('/xref/v1/symbols/GE')
  initGet('/xref/v1/symbols/INFO')
  initGet('/xref/v1/symbols/MSFT')
  initGet('/xref/v1/symbols/QQQ')
  initPost('/xref/v1/symbols', null, ({ data }) => {
    const symbols = JSON.parse(data).symbols
    const xrefs = MockController.get('/xref/v1/symbols').data.items
    const items = symbols.map(({ symbol }) => xrefs.find(xref => xref.symbol === symbol))
    return [ 200, { data: { items } }]
  })
  initGet('/xref/v1/venues?term=US0378331005')
  initGet('/xref/v1/venues?term=594918104')

  // USER-MANAGEMENT
  initGet('/user-management/v1/preferences/watchlists')
  initGet('/user-management/v1/preferences/custom-views')
  initPost('/user-management/v1/preferences/custom-views', null, () => [ 200 ])
  initGet('/user-management/v1/preferences/selected-view')
  initPost('/user-management/v1/preferences/selected-view', null, () => [ 200 ])

  // CHARTS-SPARK
  initPost('/charts-spark/v1/spark', null, ({ data }) => {
    const instruments = JSON.parse(data).instruments
    const charts = MockController.get('/charts-spark/v1/spark').data
    const response = instruments.map(instrument => charts.find(chart => +instrument.venueXid === +chart.venueXid))
    return [ 200, { data: response }]
  })

  // GRAPHQL
  initPost('/graphql', null, data => {
    let response
    switch (data.endpoint) {
      case 'economicCommentary': {
        response = [ 200, MockController.get('/graphql/economicCommentary') ]
        break
      }
      case 'globalDataWatch': {
        response = [ 200, MockController.get('/graphql/globalDataWatch') ]
        break
      }
      case 'jpRatings': {
        response = [ 200, MockController.get('/graphql/jpRatings') ]
        break
      }
      case 'ratingsChange': {
        response = [ 200, MockController.get('/graphql/ratingsChange') ]
        break
      }
      case 'regions': {
        response = [ 200, MockController.get('/graphql/regions') ]
        break
      }
      case 'researchService': {
        response = [ 200, MockController.get('/graphql/researchService') ]
        break
      }
      case 'sectors': {
        response = [ 200, MockController.get('/graphql/sectors') ]
        break
      }
      default: {
        response = [ 400, {}]
      }
    }
    return response
  })
}

/**
 * Initialize mock data
 *
 * @public
 * @function initialize
 */
const initialize = () => {
  mock = new MockAdapter(Axios, { delayResponse: 1000 })
  window.MockController = MockController

  initializeStubs()
}

/**
 * Check if currently mock data is being used.
 *
 * @public
 * @function isMockActive
 * @return {boolean} - if mock data is enabled or not
 */
const isEnabled = () => !!mock

/**
 * Stops mocking data
 *
 * @public
 * @function stop
 */
const stop = () => {
  mock.restore()
  mock = null
}

export default {
  initialize,
  isEnabled,
  stop
}
