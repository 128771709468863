import numeral from 'numeral'
import { TEXT_NULL_VALUE } from './constants'
import { validateValue } from './helpers'

const numberFormatter = (value, format) =>
  validateValue(value)
    ? numeral(value).format(format)
    : TEXT_NULL_VALUE

export const numeralize = value =>
  numeral(value).value()

// Numbers
export const number = (value, precision = 0) => {
  const format = `0.${Array(precision + 1).join('0')}`
  return numberFormatter(value, format)
}

export const numberComma = (value, precision = 0) => {
  const format = `0,0.${Array(precision + 1).join('0')}`
  return numberFormatter(value, format)
}

export const numberCommaZeroOrPrecision = (value, precision = 0) => {
  const format = `0,0.[${Array(precision + 1).join('0')}]`
  return numberFormatter(value, format)
}

export const numberCommaSigned = (value, precision = 0) => {
  const format = `0,0.${Array(precision + 1).join('0')}`
  const valueFormatted = numberFormatter(value, format)
  const startCharacter = numeralize(valueFormatted) > 0 ? '+' : ''
  return startCharacter + valueFormatted
}

export const numberFixedWidth = (value, width = 6) => {
  if (typeof value !== 'number') return TEXT_NULL_VALUE
  let stringValue = value.toString()
  const decimalIndex = stringValue.indexOf('.')
  if (decimalIndex === -1) {
    // no decimal point
    if (stringValue.length < width) {
      stringValue += '.' // add missing '.' before trailing zeroes
    }
    return stringValue.padEnd(width, '0')
  } else {
    const decimalPoints = Math.max(width - decimalIndex - 1, 0)
    return value.toFixed(decimalPoints)
  }
}

export const numberRound = (value, decimalPoints) => {
  const decimalPlace = +('1'.padEnd(decimalPoints + 1, '0')) // 10 or 100 or 1000 or etc
  return Math.round(value * decimalPlace) / decimalPlace
}

export const numberMagnitude = (value, precision = 0) => {
  const format = `0.${Array(precision + 1).join('0')}a`
  return numberFormatter(value, format).toUpperCase()
}

export const numberSigned = (value, precision = 0) => {
  if (typeof value !== 'number') return TEXT_NULL_VALUE
  const format = `0.${Array(precision + 1).join('0')}`
  const valueFormatted = numberFormatter(value, format)
  const startCharacter = numeralize(valueFormatted) > 0 ? '+' : ''
  return startCharacter + valueFormatted
}

export const percentComma = (value, precision = 0) => {
  if (typeof value !== 'number') return TEXT_NULL_VALUE
  const format = `0,0.${Array(precision + 1).join('0')}%`
  const valueFormatted = numberFormatter(value, format)
  return valueFormatted
}

export const percentCommaSigned = (value, precision = 0) => {
  if (typeof value !== 'number') return TEXT_NULL_VALUE
  const format = `0,0.${Array(precision + 1).join('0')}%`
  const valueFormatted = numberFormatter(value, format)
  const startCharacter = numeralize(valueFormatted) > 0 ? '+' : ''
  return startCharacter + valueFormatted
}

// Prices
export const price = (value, precision = 0) =>
  validateValue(value)
    ? '$' + number(value, precision)
    : TEXT_NULL_VALUE

export const priceComma = (value, precision = 0) => {
  const sign = value < 0 ? '-' : ''
  return validateValue(value)
    ? `${sign}$${numberComma(Math.abs(value), precision)}`
    : TEXT_NULL_VALUE
}

export const priceCommaZeroOrPrecision = (value, precision = 0) => {
  const sign = value < 0 ? '-' : ''
  return validateValue(value)
    ? `${sign}$${numberCommaZeroOrPrecision(Math.abs(value), precision)}`
    : TEXT_NULL_VALUE
}

export const priceCommaSigned = (value, precision = 0) => {
  const sign = value < 0 ? '-' : '+'
  return validateValue(value)
    ? `${sign}$${numberComma(Math.abs(value), precision)}`
    : TEXT_NULL_VALUE
}

export const priceMagnitude = (value, precision = 0) =>
  validateValue(value)
    ? '$' + numberMagnitude(value, precision)
    : TEXT_NULL_VALUE
