import { URL_PARAM_EMPLOYEE_PILOT, URL_PARAM_VALIDATOR_PILOT } from './constants'
import { getQueryStringParameterValue } from './helpers'

const verifyQueryString = key => {
  const pilotFlag = getQueryStringParameterValue(key)
  if (pilotFlag) {
    return pilotFlag === 'true'
  }
  return false
}
export const isEmployeePilot = () => verifyQueryString(URL_PARAM_EMPLOYEE_PILOT)
export const isValidatorPilot = () => verifyQueryString(URL_PARAM_VALIDATOR_PILOT)
