
import AlertsHistory from './fixtures/alerts/history.json'
import AlertsJpmcPrimary from './fixtures/alerts/jpmc-primary.json'
import AnnualFees from './fixtures/annual-fees/annualFees.json'
import AppleVenue from './fixtures/xref/venues/appleVenue.json'
import AssetAllocation from './fixtures/allocation/assetAllocation.json'
import AssetAllocationMutualFund from './fixtures/allocation/assetAllocationMutualFund.json'
import AssetClassification from './fixtures/asset-classification/assetClassification.json'
import BenchmarkCategories from './fixtures/benchmark-categories/benchmarkCategories.json'
import CalendarEventsByDateConferenceCallEvents from './fixtures/calendar/events-by-date/conferenceCallEvents.json'
import CalendarEventsByDateDividendEvents from './fixtures/calendar/events-by-date/dividendEvents.json'
import CalendarEventsByDateEarningsEstimatesEvents from './fixtures/calendar/events-by-date/earningsEstimatesEvents.json'
import CalendarEventsByDateEarningsEvents from './fixtures/calendar/events-by-date/earningsEvents.json'
import CalendarEventsByDateEconomicEvents from './fixtures/calendar/events-by-date/economicEvents.json'
import CalendarEventsByDateIPOEvents from './fixtures/calendar/events-by-date/ipoEvents.json'
import CalendarEventsByDateRatingsEvents from './fixtures/calendar/events-by-date/ratingsEvents.json'
import CalendarEventsByDateSplitEvents from './fixtures/calendar/events-by-date/splitEvents.json'
import CfraAnalystConfigurations from './fixtures/cfra-api/configurations.json'
import CfraAnalystQualitativeDetails from './fixtures/cfra-api/qualitative_details.json'
import ChartsSpark from './fixtures/charts-spark/spark.json'
import CurrencyConversion from './fixtures/currency-conversion/rate.json'
import CurrencyConversionResults from './fixtures/jpmc-api/currency-conversion/currency-conversion.json'
import DistributionsCurrent from './fixtures/distibutions/current.json'
import DividendsCurrent from './fixtures/corporateactions-dividends/205778/current.json'
import DividendsCurrentEtf from './fixtures/corporateactions-dividends/238029/current.json'
import DividendsHistoricalEvents from './fixtures/corporateactions-dividends/205778/historical-events.json'
import DividendsHistoricalEventsEtf from './fixtures/corporateactions-dividends/238029/historical-events.json'
import EndOfDayPricingStatisticsMockData from './fixtures/pricing-current-end-day-stats/end-of-day-pricing-statistics-details.json'
import EquitiesMorningStarEarnings from './fixtures/equities/morningstarEarnings.json'
import EquitiesMorningStarEarningsCurrent from './fixtures/equities/morningstarEarningsCurrent.json'
import EquitiesMorningStarEstimates from './fixtures/equities/morningstarEstimates.json'
import EquitiesMorningStarEstimatesRecommendations from './fixtures/equities/morningstarEstimatesRecommendations.json'
import EquitiesMorningStarPeers from './fixtures/equities/morningstarPeersDetails.json'
import FundAttributes from './fixtures/fund-details/fundDetails.json'
import FundsCategoryName from './fixtures/jpmc-api/funds/category-name.json'
import FundsDividendYield from './fixtures/jpmc-api/funds/dividend-yield.json'
import FundsGrossExpenseRatio from './fixtures/jpmc-api/funds/gross-expense-ratio.json'
import FundsTradeStatus from './fixtures/jpmc-api/funds/trade-status.json'
import GeneralInformation from './fixtures/general-information/generalInformation.json'
import GraphQlJpRatings from './fixtures/graphql/jpRatings.json'
import GraphqlEconomicCommentary from './fixtures/graphql/economicCommentary.json'
import GraphqlGlobalDataWatch from './fixtures/graphql/globalDataWatch.json'
import GraphqlRatingsChange from './fixtures/graphql/ratingsChange.json'
import GraphqlRegions from './fixtures/graphql/regions.json'
import GraphqlResearchService from './fixtures/graphql/researchService.json'
import GraphqlSectors from './fixtures/graphql/sectors.json'
import JpmcResearchRic from './fixtures/jpmc-research/ric.json'
import ManagedFundsCompanyInformation from './fixtures/managedfunds/morningstarCompanyInformation.json'
import ManagedFundsMorningstarShareClass from './fixtures/managedfunds/morningstarShareClass.json'
import ManagedFundsMorningstarStrategy from './fixtures/managedfunds/morningstarStrategy.json'
import ManagedFundsMorningstarTopHoldings from './fixtures/managedfunds/morningstarTopHoldings.json'
import ManagedFundsMorningstarYield from './fixtures/managedfunds/morningstarYield.json'
import MarketCapAllocation from './fixtures/allocation/marketCapAllocation.json'
import MarketCapAllocationMutualFund from './fixtures/allocation/marketCapAllocationMutualFund.json'
import MarketRankingStatsData from './fixtures/market-rankings/marketRankingsStats.json'
import MarketRankingsMockData from './fixtures/market-rankings/marketRankingsRankings.json'
import MarketsExchangeInformation from './fixtures/markets/marketsExchangeInformation.json'
import MicrosoftVenue from './fixtures/xref/venues/microsoftVenue.json'
import MorningStarPortfolioStatsMutualFund from './fixtures/portfolio-stats/portfolioStatsMutualFund.json'
import MorningstarDistributionsHistorical from './fixtures/managedfunds/morningstarDistributionsHistorical'
import MorningstarPortfolioStats from './fixtures/portfolio-stats/portfolioStats.json'
import MorningstarRatingsRisk from './fixtures/ratings-risk/ratingsRisk.json'
import MorningstarRatingsRiskMutualFund from './fixtures/ratings-risk/ratingsRiskMutualFund.json'
import MorningstareTopholdingsReverse from './fixtures/reverse-lookup/reverseLookup.json'
import MutualFundsGrowth10K from './fixtures/jpmc-api/mutualfunds/growth10k/52709395.json'
import NewsArticleNAMockData from './fixtures/news/3109/articles/article2.json'
import NewsArticleNAPresMockData from './fixtures/news/3109/articles/3024-14971270-18603162/component-type/PRES.json'
import NewsArticlesMockData from './fixtures/news/3109/articles/articles.json'
import OptionsChainWithQuotesMockData from './fixtures/optionchains/options/chainWithQuotes.json'
import OptionsExpirationDatesMockData from './fixtures/optionchains/options/expirationDates.json'
import PerformanceChartData from './fixtures/jpmc-api/performance/chart-data.json'
import PerformanceMockData from './fixtures/performance/morningstarPerformance.json'
import Profile from './fixtures/alerts/profile.json'
import ProsepectusFees from './fixtures/prospectus-fees/prospectusFees.json'
import ProspectusFeesBUFDX from './fixtures/prospectus-fees/porspectusFeesBUFDX.json'
import PurchaseDetails from './fixtures/purchase-details/purchaseDetails.json'
import QuarterlyMockData from './fixtures/performance/morningstarQuarterEndPerformance.json'
import QuoteChangePercentData from './fixtures/quote-change-percent/quoteChangePercent.json'
import QuoteDetailsMockData from './fixtures/quote/details.json'
import RatiosMockData from './fixtures/ratios/details.json'
import RegionAllocation from './fixtures/allocation/regionAllocation.json'
import RegionAllocationMutualFund from './fixtures/allocation/regionAllocationMutualFund.json'
import SectorAllocation from './fixtures/allocation/sectorAllocationMutualFund.json'
import SectorAllocationMutualFund from './fixtures/allocation/sectorAllocation.json'
import SixCircleFundList from './fixtures/six-circle/fundList.json'
import SixCircleFundStrategy from './fixtures/six-circle/fundStrategy.json'
import Subscriptions from './fixtures/alerts/subscriptions.json'
import TreasuryYieldCurve from './fixtures/treasury-yield-curve/treasuryYield.json'
import UserPreferencesCustomViews from './fixtures/user-preferences/custom-views/details.json'
import UserPreferencesSelectedView from './fixtures/user-preferences/selected-view/details.json'
import UserPreferencesWatchlists from './fixtures/user-preferences/watchlists/details.json'
import VenueXidsMockData from './fixtures/xref/venueXids.json'
import WatchlistHoldingsPostMockData from './fixtures/watchlist/watchlist/post/holdings.json'
import WatchlistHoldingsWatchlist1 from './fixtures/watchlist/watchlist/5c4a0e50-6845-4c20-be84-a32eaf1f79d0/holdings.json'
import WatchlistHoldingsWatchlist2 from './fixtures/watchlist/watchlist/3714bf05-32b4-46c6-9276-0df253447575/holdings.json'
import WatchlistHoldingsWatchlist3 from './fixtures/watchlist/watchlist/06524c17-5cbf-482d-af9f-0ba990134fb4/holdings.json'
import WatchlistHoldingsWatchlist4 from './fixtures/watchlist/watchlist/257B2DF8-D72C-4E8B-86E9-323E8D4A811E/holdings.json'
import WatchlistMockData from './fixtures/watchlist/watchlist.json'
import WatchlistPostMockData from './fixtures/watchlist/post/watchlist.json'
import XrefSymbols from './fixtures/xref/symbols.json'
import XrefSymbolsAAPL from './fixtures/xref/symbols/AAPL.json'
import XrefSymbolsANOIX from './fixtures/xref/symbols/ANOIX.json'
import XrefSymbolsBUFDX from './fixtures/xref/symbols/BUFDX.json'
import XrefSymbolsGE from './fixtures/xref/symbols/GE.json'
import XrefSymbolsINFO from './fixtures/xref/symbols/INFO.json'
import XrefSymbolsMSFT from './fixtures/xref/symbols/MSFT.json'
import XrefSymbolsQQQ from './fixtures/xref/symbols/QQQ.json'
import XrefVenuesMockData from './fixtures/xref/venues.json'
import * as Constants from '../jpmc-qr-ui-common/utils/constants'
import * as Helpers from '../jpmc-qr-ui-common/utils/helpers'
import * as SparkLineImages from './fixtures/apiman-gateway/MOD/chartworks-image/Chart/sparkLine.js'

const VALID_ENDPOINTS = [
  '/alerts/v1/sortable-history',
  '/alerts/v1/profile',
  '/alerts/v1/subscriptions',
  '/alerts/v1/verification/status/jpmc-primary',
  '/apiman-gateway/MOD/chartworks-image/1.0/Chart/sparkLine',
  '/calendar/v1/eventsByDate?eventName=ConferenceCall%20Events',
  '/calendar/v1/eventsByDate?eventName=Dividend%20Events',
  '/calendar/v1/eventsByDate?eventName=Economic%20Events',
  '/calendar/v1/eventsByDate?eventName=Earnings%20Events',
  '/calendar/v1/eventsByDate?eventName=Guidance%20Events',
  '/calendar/v1/eventsByDate?eventName=IPO%20Events',
  '/calendar/v1/eventsByDate?eventName=Rating%20Events',
  '/calendar/v1/eventsByDate?eventName=Split%20Events',
  '/charts-spark/v1/spark',
  '/corporateactions-dividends/v1/205778/current',
  '/corporateactions-dividends/v1/205778/historical-events',
  '/corporateactions-dividends/v1/238029/current',
  '/corporateactions-dividends/v1/238029/historical-events',
  '/currency-conversion/v1/rate',
  '/cfra/research/205778',
  '/equities-morningstar-assetclassification/v1/details/205778',
  '/equities-morningstar-earnings/v1/details/205778',
  '/equities-morningstar-estimates/v1/current/205778',
  '/equities-morningstar-estimates/v1/recommendations/205778',
  '/equities-morningstar-estimates/v1/trends/205778',
  '/equities-morningstar-financialratios/v1/details/205778',
  '/equities-morningstar-generalinformation/v1/details/205778',
  '/equities-morningstar-peers/v1/details/205778',
  '/graphql/economicCommentary',
  '/graphql/globalDataWatch',
  '/graphql/jpRatings',
  '/graphql/ratingsChange',
  '/graphql/regions',
  '/graphql/researchService',
  '/graphql/sectors',
  '/jpmc-qr-api/v1/currency-conversion',
  '/jpmc-qr-api/v1/funds/category-name/238029',
  '/jpmc-qr-api/v1/funds/category-name/52709395',
  '/jpmc-qr-api/v1/funds/dividend-yield/238029',
  '/jpmc-qr-api/v1/funds/dividend-yield/52709395',
  '/jpmc-qr-api/v1/funds/gross-expense-ratio/238029',
  '/jpmc-qr-api/v1/funds/gross-expense-ratio/52709395',
  '/jpmc-qr-api/v1/funds/six-circle-funds',
  '/jpmc-qr-api/v1/funds/six-circle-fund/strategy/52709395',
  '/jpmc-qr-api/v1/funds/trade-status',
  '/jpmc-qr-api/v1/treasury-yield-curve',
  '/jpmc-qr-api/v1/mutualfunds/growth10k/52709395',
  '/jpmc-qr-api/v1/performance/chart-data/205778',
  '/jpmc-qr-api-research/v1/ric',
  '/managedfunds-morningstar-annualfees/v1/details/52709395',
  '/managedfunds-morningstar-assetallocation/v1/asset-allocation/238029',
  '/managedfunds-morningstar-assetallocation/v1/asset-allocation/52709395',
  '/managedfunds-morningstar-benchmarkinformation/v1/categories/238029',
  '/managedfunds-morningstar-companyinformation/v1/company-information/52709395',
  '/managedfunds-morningstar-distributionscurrent/v1/details/238029',
  '/managedfunds-morningstar-distributionshistorical/v1/details/238029',
  '/managedfunds-morningstar-fundattributes/v1/details/52709395',
  '/managedfunds-morningstar-geographicallocation/v1/regions/238029',
  '/managedfunds-morningstar-geographicallocation/v1/regions/52709395',
  '/managedfunds-morningstar-marketcapallocation/v1/details/238029',
  '/managedfunds-morningstar-marketcapallocation/v1/details/52709395',
  '/managedfunds-morningstar-performance/v1/trailing-month-end/238029',
  '/managedfunds-morningstar-performance/v1/trailing-month-end/52709395',
  '/managedfunds-morningstar-performance-quarterend/v1/quarterend-trailing-performance',
  '/managedfunds-morningstar-portfoliostats/v1/details/238029',
  '/managedfunds-morningstar-portfoliostats/v1/details/52709395',
  '/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/238029',
  '/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/52709395',
  '/managedfunds-morningstar-purchasedetails/v1/purchase-details/52709395',
  '/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/238029',
  '/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/52709395',
  '/managedfunds-morningstar-sectorallocation/v1/sector-allocation/238029',
  '/managedfunds-morningstar-sectorallocation/v1/sector-allocation/52709395',
  '/managedfunds-morningstar-shareclass/v1/share-class',
  '/managedfunds-morningstar-strategy/v1/details',
  '/managedfunds-morningstar-topholdings/v1/topHoldings',
  '/managedfunds-morningstar-topholdingsreverselookup/v1/topholdingsreverselookup',
  '/managedfunds-morningstar-yield/v1/details',
  '/market-rankings/v1/stats',
  '/market-rankings/v1/detail',
  'markets-exchangeinformation/v1/details',
  '/news/v1/3109/articles',
  '/news/v1/3109/articles/3024-14971270-18603162',
  '/news/v1/3109/articles/3024-14971270-18603162/component-type/PRES',
  '/optionchains/v1/options/chainwithquotes',
  '/optionchains/v1/options/expirationdates',
  '/optionchains/v1/options/expirationdates',
  '/pricing-current-endofdaystats/v1/details',
  '/quote/v1/details',
  '/quote-changepercent/v1/',
  '/research/v1/configurations',
  '/research/v1/475/details',
  '/user-management/v1/preferences/custom-views',
  '/user-management/v1/preferences/selected-view',
  '/user-management/v1/preferences/watchlists',
  '/watchlist/v1/watchlist',
  '/watchlist/v1/watchlist/06524c17-5cbf-482d-af9f-0ba990134fb4/holdings',
  '/watchlist/v1/watchlist/257B2DF8-D72C-4E8B-86E9-323E8D4A811E/holdings',
  '/watchlist/v1/watchlist/3714bf05-32b4-46c6-9276-0df253447575/holdings',
  '/watchlist/v1/watchlist/5c4a0e50-6845-4c20-be84-a32eaf1f79d0/holdings',
  '/watchlist/v1/watchlist/holdings/post',
  '/watchlist/v1/watchlist/post',
  '/xref/v1/symbols',
  '/xref/v1/symbols/AAPL',
  '/xref/v1/symbols/ANOIX',
  '/xref/v1/symbols/BUFDX',
  '/xref/v1/symbols/GE',
  '/xref/v1/symbols/INFO',
  '/xref/v1/symbols/MSFT',
  '/xref/v1/symbols/QQQ',
  '/xref/v1/venue-xids',
  '/xref/v1/venues',
  '/xref/v1/venues?term=US0378331005',
  '/xref/v1/venues?term=594918104'
]

const DEFAULT_ENDPOINT_VALUES = {
  '/alerts/v1/sortable-history': AlertsHistory,
  '/alerts/v1/profile': Profile,
  '/alerts/v1/subscriptions': Subscriptions,
  '/alerts/v1/verification/status/jpmc-primary': AlertsJpmcPrimary,
  '/apiman-gateway/MOD/chartworks-image/1.0/Chart/sparkLine': SparkLineImages,
  '/calendar/v1/eventsByDate?eventName=ConferenceCall%20Events': CalendarEventsByDateConferenceCallEvents,
  '/calendar/v1/eventsByDate?eventName=Dividend%20Events': CalendarEventsByDateDividendEvents,
  '/calendar/v1/eventsByDate?eventName=Earnings%20Events': CalendarEventsByDateEarningsEvents,
  '/calendar/v1/eventsByDate?eventName=Guidance%20Events': CalendarEventsByDateEarningsEstimatesEvents,
  '/calendar/v1/eventsByDate?eventName=Economic%20Events': CalendarEventsByDateEconomicEvents,
  '/calendar/v1/eventsByDate?eventName=IPO%20Events': CalendarEventsByDateIPOEvents,
  '/calendar/v1/eventsByDate?eventName=Rating%20Events': CalendarEventsByDateRatingsEvents,
  '/calendar/v1/eventsByDate?eventName=Split%20Events': CalendarEventsByDateSplitEvents,
  '/currency-conversion/v1/rate': CurrencyConversion,
  '/charts-spark/v1/spark': ChartsSpark,
  '/corporateactions-dividends/v1/205778/current': DividendsCurrent,
  '/corporateactions-dividends/v1/205778/historical-events': DividendsHistoricalEvents,
  '/corporateactions-dividends/v1/238029/current': DividendsCurrentEtf,
  '/corporateactions-dividends/v1/238029/historical-events': DividendsHistoricalEventsEtf,
  '/research/v1/configurations': CfraAnalystConfigurations,
  '/research/v1/475/details': CfraAnalystQualitativeDetails,
  '/equities-morningstar-assetclassification/v1/details/205778': AssetClassification,
  '/equities-morningstar-earnings/v1/details/205778': EquitiesMorningStarEarnings,
  '/equities-morningstar-estimates/v1/current/205778': EquitiesMorningStarEarningsCurrent,
  '/equities-morningstar-estimates/v1/recommendations/205778': EquitiesMorningStarEstimatesRecommendations,
  '/equities-morningstar-estimates/v1/trends/205778': EquitiesMorningStarEstimates,
  '/equities-morningstar-financialratios/v1/details/205778': RatiosMockData,
  '/equities-morningstar-generalinformation/v1/details/205778': GeneralInformation,
  '/equities-morningstar-peers/v1/details/205778': EquitiesMorningStarPeers,
  '/graphql/economicCommentary': GraphqlEconomicCommentary,
  '/graphql/globalDataWatch': GraphqlGlobalDataWatch,
  '/graphql/jpRatings': GraphQlJpRatings,
  '/graphql/ratingsChange': GraphqlRatingsChange,
  '/graphql/regions': GraphqlRegions,
  '/graphql/researchService': GraphqlResearchService,
  '/graphql/sectors': GraphqlSectors,
  '/jpmc-qr-api/v1/currency-conversion': CurrencyConversionResults,
  '/jpmc-qr-api/v1/funds/category-name/238029': FundsCategoryName,
  '/jpmc-qr-api/v1/funds/category-name/52709395': FundsCategoryName,
  '/jpmc-qr-api/v1/funds/dividend-yield/238029': FundsDividendYield,
  '/jpmc-qr-api/v1/funds/dividend-yield/52709395': FundsDividendYield,
  '/jpmc-qr-api/v1/funds/gross-expense-ratio/238029': FundsGrossExpenseRatio,
  '/jpmc-qr-api/v1/funds/gross-expense-ratio/52709395': FundsGrossExpenseRatio,
  '/jpmc-qr-api/v1/funds/six-circle-funds': SixCircleFundList,
  '/jpmc-qr-api/v1/funds/six-circle-fund/strategy/52709395': SixCircleFundStrategy,
  '/jpmc-qr-api/v1/funds/trade-status': FundsTradeStatus,
  '/jpmc-qr-api/v1/treasury-yield-curve': TreasuryYieldCurve,
  '/jpmc-qr-api/v1/mutualfunds/growth10k/52709395': MutualFundsGrowth10K,
  '/jpmc-qr-api/v1/performance/chart-data/205778': PerformanceChartData,
  '/jpmc-qr-api-research/v1/ric': JpmcResearchRic,
  '/managedfunds-morningstar-annualfees/v1/details/52709395': AnnualFees,
  '/managedfunds-morningstar-assetallocation/v1/asset-allocation/238029': AssetAllocation,
  '/managedfunds-morningstar-assetallocation/v1/asset-allocation/52709395': AssetAllocationMutualFund,
  '/managedfunds-morningstar-benchmarkinformation/v1/categories/238029': BenchmarkCategories,
  '/managedfunds-morningstar-companyinformation/v1/company-information/52709395': ManagedFundsCompanyInformation,
  '/managedfunds-morningstar-distributionscurrent/v1/details/238029': DistributionsCurrent,
  '/managedfunds-morningstar-distributionshistorical/v1/details/238029': MorningstarDistributionsHistorical,
  '/managedfunds-morningstar-fundattributes/v1/details/52709395': FundAttributes,
  '/managedfunds-morningstar-geographicallocation/v1/regions/238029': RegionAllocation,
  '/managedfunds-morningstar-geographicallocation/v1/regions/52709395': RegionAllocationMutualFund,
  '/managedfunds-morningstar-marketcapallocation/v1/details/238029': MarketCapAllocation,
  '/managedfunds-morningstar-marketcapallocation/v1/details/52709395': MarketCapAllocationMutualFund,
  '/managedfunds-morningstar-performance/v1/trailing-month-end/52709395': PerformanceMockData,
  '/managedfunds-morningstar-performance/v1/trailing-month-end/238029': PerformanceMockData,
  '/managedfunds-morningstar-portfoliostats/v1/details/238029': MorningstarPortfolioStats,
  '/managedfunds-morningstar-portfoliostats/v1/details/52709395': MorningStarPortfolioStatsMutualFund,
  '/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/238029': ProsepectusFees,
  '/managedfunds-morningstar-prospectusfees/v1/prospectus-fees/52709395': ProspectusFeesBUFDX,
  '/managedfunds-morningstar-purchasedetails/v1/purchase-details/52709395': PurchaseDetails,
  '/managedfunds-morningstar-performance-quarterend/v1/quarterend-trailing-performance': QuarterlyMockData,
  '/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/238029': MorningstarRatingsRisk,
  '/managedfunds-morningstar-ratingsandrisk/v1/ratings-and-risk/52709395': MorningstarRatingsRiskMutualFund,
  '/managedfunds-morningstar-sectorallocation/v1/sector-allocation/238029': SectorAllocation,
  '/managedfunds-morningstar-sectorallocation/v1/sector-allocation/52709395': SectorAllocationMutualFund,
  '/managedfunds-morningstar-shareclass/v1/share-class': ManagedFundsMorningstarShareClass,
  '/managedfunds-morningstar-strategy/v1/details': ManagedFundsMorningstarStrategy,
  '/managedfunds-morningstar-topholdings/v1/topHoldings': ManagedFundsMorningstarTopHoldings,
  '/managedfunds-morningstar-topholdingsreverselookup/v1/topholdingsreverselookup': MorningstareTopholdingsReverse,
  '/managedfunds-morningstar-yield/v1/details': ManagedFundsMorningstarYield,
  '/market-rankings/v1/stats': MarketRankingStatsData,
  '/market-rankings/v1/detail': MarketRankingsMockData,
  '/markets-exchangeinformation/v1/details': MarketsExchangeInformation,
  '/news/v1/3109/articles': NewsArticlesMockData,
  '/news/v1/3109/articles/3024-14971270-18603162': NewsArticleNAMockData,
  '/news/v1/3109/articles/3024-14971270-18603162/component-type/PRES': NewsArticleNAPresMockData,
  '/optionchains/v1/options/chainwithquotes': OptionsChainWithQuotesMockData,
  '/optionchains/v1/options/expirationdates': OptionsExpirationDatesMockData,
  '/pricing-current-endofdaystats/v1/details': EndOfDayPricingStatisticsMockData,
  '/quote/v1/details': QuoteDetailsMockData,
  '/quote-changepercent/v1/': QuoteChangePercentData,
  '/user-management/v1/preferences/custom-views': UserPreferencesCustomViews,
  '/user-management/v1/preferences/selected-view': UserPreferencesSelectedView,
  '/user-management/v1/preferences/watchlists': UserPreferencesWatchlists,
  '/watchlist/v1/watchlist': WatchlistMockData,
  '/watchlist/v1/watchlist/06524c17-5cbf-482d-af9f-0ba990134fb4/holdings': WatchlistHoldingsWatchlist3,
  '/watchlist/v1/watchlist/257B2DF8-D72C-4E8B-86E9-323E8D4A811E/holdings': WatchlistHoldingsWatchlist4,
  '/watchlist/v1/watchlist/3714bf05-32b4-46c6-9276-0df253447575/holdings': WatchlistHoldingsWatchlist2,
  '/watchlist/v1/watchlist/5c4a0e50-6845-4c20-be84-a32eaf1f79d0/holdings': WatchlistHoldingsWatchlist1,
  '/watchlist/v1/watchlist/holdings/post': WatchlistHoldingsPostMockData,
  '/watchlist/v1/watchlist/post': WatchlistPostMockData,
  '/xref/v1/symbols': XrefSymbols,
  '/xref/v1/symbols/AAPL': XrefSymbolsAAPL,
  '/xref/v1/symbols/ANOIX': XrefSymbolsANOIX,
  '/xref/v1/symbols/BUFDX': XrefSymbolsBUFDX,
  '/xref/v1/symbols/GE': XrefSymbolsGE,
  '/xref/v1/symbols/INFO': XrefSymbolsINFO,
  '/xref/v1/symbols/MSFT': XrefSymbolsMSFT,
  '/xref/v1/symbols/QQQ': XrefSymbolsQQQ,
  '/xref/v1/venue-xids': VenueXidsMockData,
  '/xref/v1/venues': XrefVenuesMockData,
  '/xref/v1/venues?term=US0378331005': AppleVenue,
  '/xref/v1/venues?term=594918104': MicrosoftVenue
}

/**
 * Clear a run time set mock data
 *
 * @public
 * @function clear
 * @param {string} endpoint - the endpoint whose mock data is required
 * @returns {boolean} - if endpoint was cleared or not
 */
const clear = endpoint => {
  const isCleared = VALID_ENDPOINTS.includes(endpoint)
  if (isCleared) sessionStorage.removeItem(endpoint)
  return isCleared
}

/**
 * Clears all run time set mock data
 *
 * @public
 * @function clearAll
 * @param {string} endpoint - the endpoint whose mock data is required
 * @returns {number} - number of endpoints cleared
 */
const clearAll = () => {
  let count = 0
  VALID_ENDPOINTS.forEach(validEndpoint => {
    if (sessionStorage.getItem(validEndpoint) !== null) {
      count++
    }
    sessionStorage.removeItem(validEndpoint)
  })
  return count
}

/**
 * Enables fetching mock data for a particular end point
 *
 * @public
 * @method get
 * @param {string} endpoint - the endpoint whose mock data is required
 * @returns {Object|null} - the required mock data or null
 */
const get = endpoint => {
  if (VALID_ENDPOINTS.indexOf(endpoint) !== -1) {
    const storedValue = sessionStorage.getItem(endpoint)

    return storedValue || DEFAULT_ENDPOINT_VALUES[endpoint]
  }

  return null
}

/**
 * Enables overriding mock data for ease of testing
 *
 * @public
 * @method set
 * @param {string} endpoint - the endpoint mock to override
 * @param {Object} value - the value to override
 */
const set = (endpoint, value) => {
  // a safety measure. THIS SHOULD NEVER BE ALLOWED WHEN MOCK DATA IS OFF. Cross Scripting issue
  const isMockDataEnabled = Helpers.getQueryStringParameterValue(Constants.URL_PARAM_MOCK_DATA)
  if ((isMockDataEnabled === true || isMockDataEnabled === 'true') && VALID_ENDPOINTS.includes(endpoint)) sessionStorage.setItem(endpoint, value)
}

export default {
  clear,
  clearAll,
  get,
  set
}
