import './index.module.scss'
import 'babel-polyfill'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

// ReactDOM.render will be deprecated in ReactDOM 18
// the new method is createRoot
// https://react.dev/reference/react-dom/render
// https://react.dev/reference/react-dom/client/createRoot
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
