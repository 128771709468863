import CircularProgress from '@mui/material/CircularProgress'
import MockAdapter from './mock-adapter/index'
import React, { Component, Suspense, lazy } from 'react'
import TagManager from 'react-gtm-module'
import packageJson from '../package.json'
import smoothscroll from 'smoothscroll-polyfill'
import * as Sentry from '@sentry/browser'
import { BREAKPOINT } from './jpmc-qr-ui-common/styles/variables'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import {
  ENVIRONMENT_LOCALHOST,
  ENVIRONMENT_PRODUCTION,
  ENVIRONMENT_PRODUCTION_CTC,
  ENVIRONMENT_PRODUCTION_PTC,
  KEY_SESSION_STORAGE_ACCESS_TOKEN,
  KEY_SESSION_STORAGE_DATA_ENVIRONMENT_OVERRIDE,
  KEY_SESSION_STORAGE_INTERNAL_API,
  KEY_SESSION_STORAGE_SHOW_DEBUG_INFO,
  URL_PARAM_ACCESS_TOKEN,
  URL_PARAM_DATA_ENVIRONMENT_OVERRIDE,
  URL_PARAM_INTERNAL_API,
  URL_PARAM_MOCK_DATA,
  URL_PARAM_SHOW_DEBUG_INFO,
  URL_PARAM_STAND_ALONE
} from './jpmc-qr-ui-common/utils/constants'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import {
  getEnvironment,
  getQueryStringParameterValue
} from './jpmc-qr-ui-common/utils/helpers'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: +BREAKPOINT.XSMALL_MIN.replace('px', ''),
      sm: +BREAKPOINT.SMALL_MAX.replace('px', ''),
      md: +BREAKPOINT.MEDIUM_MAX.replace('px', ''),
      lg: +BREAKPOINT.LARGE_MAX.replace('px', ''),
      xl: +BREAKPOINT.XLARGE_MIN.replace('px', '')
    }
  },
  typography: {
    allVariants: {
      fontFamily: window.getComputedStyle(document.body).fontFamily,
      fontSize: 14
    }
  }
})
class App extends Component {
  constructor () {
    super()

    this.environment = {
      current: getEnvironment(),
      isProd: function () {
        return this.current === ENVIRONMENT_PRODUCTION ||
        this.current === ENVIRONMENT_PRODUCTION_CTC ||
        this.current === ENVIRONMENT_PRODUCTION_PTC
      }
    }

    this.initAccessToken()
    this.initDataEnvironmentOverride()
    this.initInternalApi()
    this.initMockData()
    this.initPageScroll()
    this.initSentry()
    this.initShowDebugInfo()
    this.initSmoothScroll()
    this.initGoogleAnalytics()

    console.info('build-time: ', window.MD.buildTime)
    console.info(`App version: ${packageJson.version}`)
  }

  initAccessToken = () => {
    const valueParam = getQueryStringParameterValue(URL_PARAM_ACCESS_TOKEN)

    if (!valueParam) return

    sessionStorage.setItem(
      KEY_SESSION_STORAGE_ACCESS_TOKEN,
      valueParam
    )
  }

  initDataEnvironmentOverride = () => {
    const valueParam = getQueryStringParameterValue(URL_PARAM_DATA_ENVIRONMENT_OVERRIDE)

    if (!valueParam) return

    sessionStorage.setItem(
      KEY_SESSION_STORAGE_DATA_ENVIRONMENT_OVERRIDE,
      valueParam
    )
  }

  initGoogleAnalytics = () => {
    if (
      window &&
      window.location &&
      (window.location.pathname === '/integration' || window.location.pathname === '/jump')
    ) return

    const MD = window.MD || {}
    const tagManagerArgs = {
      gtmId: MD.gtmId,
      auth: MD.gtmAuth || '',
      preview: MD.gtmPreview || ''
    }

    TagManager.initialize(tagManagerArgs)
  }

  initInternalApi = () => {
    const valueParam = getQueryStringParameterValue(URL_PARAM_INTERNAL_API)
    const isInternalApiOn = valueParam === 'on'

    sessionStorage.setItem(
      KEY_SESSION_STORAGE_INTERNAL_API,
      isInternalApiOn
    )
  }

  initMockData = () => {
    const isMockDataEnabled = getQueryStringParameterValue(URL_PARAM_MOCK_DATA)

    if (isMockDataEnabled !== 'true') return

    MockAdapter.initialize()
  }

  initPageScroll = () => {
    if (getQueryStringParameterValue(URL_PARAM_STAND_ALONE) === 'true') document.documentElement.style.overflow = 'auto'
  }

  initSentry = () => {
    if (this.environment.current === ENVIRONMENT_LOCALHOST) return

    const dsn = this.environment.isProd()
      ? 'https://1418d2aea954491cbeb790d13b4d2880@logging-api.markitdigital.com/12'
      : 'https://b86c1ba3772241cbbb17edd6d3bfdd39@logging-api.markitqa.com/12'

    Sentry.init({
      dsn: dsn,
      environment: this.environment.current,
      debug: this.environment.current === ENVIRONMENT_LOCALHOST,
      release: packageJson.version
    })
  }

  initShowDebugInfo = () => {
    const valueParam = getQueryStringParameterValue(URL_PARAM_SHOW_DEBUG_INFO)
    const isShowDebugInfoOn = valueParam === 'on'

    sessionStorage.setItem(
      KEY_SESSION_STORAGE_SHOW_DEBUG_INFO,
      isShowDebugInfoOn
    )
  }

  initSmoothScroll = () => {
    smoothscroll.polyfill()
  }

  render () {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<CircularProgress />} >
            <Switch>
              <Route
                exact
                path='/'
                render={() => this.environment.isProd()
                  ? <Redirect to='/' />
                  : <Redirect to='/jump' />
                }
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-alerts/components/Alerts'))}
                exact
                path='/alerts'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-alertscreate/components/AlertsCreate'))}
                exact
                path='/alerts/create'
              />
              <Route
                component={lazy(() => import('./article/components/Article'))}
                exact
                path='/article'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-cfraresearch/components/CfraResearch'))}
                exact
                path='/cfra/research'
              />
              <Route
                component={lazy(() => import('./cross-rates/components/CrossRates'))}
                exact
                path='/crossrates'
              />
              <Route
                component={lazy(() => import('./markets-summary-news/components/MarketsSummaryNews'))}
                exact
                path='/markets/summary/news'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-etfquote/components/EtfQuote'))}
                exact
                path='/etf/quote'
              />
              <Route
                component={lazy(() => import('./routes/integration/Integration'))}
                exact
                path='/integration'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-internationalstockquote/components/InternationalStockQuote'))}
                exact
                path='/international/stock/quote'
              />
              <Route
                component={lazy(() => import('./routes/jump/Jump'))}
                exact
                path='/jump'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-marketcalendar/components/MarketCalendar'))}
                exact
                path='/market/calendar'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-marketsoverview/components/MarketsOverview'))}
                exact
                path='/markets/overview'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-mutualfundquote/components/MutualFundQuote'))}
                exact
                path='/mutualfund/quote'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-newsexperience/components/NewsExperience'))}
                exact
                path='/news/experience'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-newsexperiencearticle/components/NewsExperienceArticle'))}
                exact
                path='/news/experience/article'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-newsexperiencesearchresults/components/NewsExperienceSearchResults'))}
                exact
                path='/news/experience/search'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-options/components/Options'))}
                exact
                path='/options'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-researchanalyst/components/ResearchAnalyst'))}
                exact
                path='/research/analyst'
              />
              <Route
                component={lazy(() => import('./stock-chart/components/StockChart'))}
                exact
                path='/stock/chart'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-stockquote/components/StockQuote'))}
                exact
                path='/stock/quote'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-stockquote/components/all-analysts/AllAnalysts'))}
                exact
                path='/stock/quote/all-analysts'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-todaysmovers/components/TodaysMovers'))}
                exact
                path='/todays-movers'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/Watchlists'))}
                exact
                path='/watchlists'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsAddSymbol'))}
                exact
                path='/watchlists/add-symbol'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsCreateCustomView'))}
                exact
                path='/watchlists/create-custom-view'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsCreateWatchlist'))}
                exact
                path='/watchlists/create-watchlist'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsDeleteCustomView'))}
                exact
                path='/watchlists/delete-custom-view'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsDeleteWatchlist'))}
                exact
                path='/watchlists/delete-watchlist'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsEditCustomView'))}
                exact
                path='/watchlists/edit-custom-view'
              />
              <Route
                component={lazy(() => import('./jpmc-qr-ui-watchlists/routes/WatchlistsEditWatchlist'))}
                exact
                path='/watchlists/edit-watchlist'
              />
              <Route
                component={lazy(() => import('./alerts-landing/AlertsLanding'))}
                exact
                path='/alerts/landing'
              />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App
